import "./sass/main.scss";
import showMenu from "./js/menu.js";


window.addEventListener('load', e => {
    const btn_menu = document.getElementById('menu-toggle');
    btn_menu.addEventListener('click', showMenu )

    

    //Carga de video
    let ancho = screen.width;
    if (ancho < 600) {
        let video = document.getElementById('video_principal');
        video.setAttribute('src', 'static/video_vertical.mp4')
    }
    
})